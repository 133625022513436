import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Divider,
  Flex,
  FormControl,
  Input,
  Link,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { Auth } from 'aws-amplify';
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { COGNITO_CHALLENGES } from 'constants/amplify';
import {
  LOGIN_STARTED,
  MAX_LOGIN_ATTEMPTS_WITHOUT_HELP,
  ROUTES,
} from 'constants/constants';
import { useAuth } from 'context/authContext';
import ErrorMessage from 'components/ErrorMessage';
import { handleCognitoError } from 'utils/errors';
import { ICognitoError } from 'types/types';
import { deleteCookie, filterCookies, setCookieValue } from 'utils/cookie';
import * as logger from 'utils/logger';
import * as url from 'utils/url';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isEmailError, setIsEmailError] = useState(false);
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [numSignInAttempts, setNumSignInAttempts] = useState(0);
  const { user, setUser, accessError, saveAccessError } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const hasTimedOut = searchParams.get('timeout') !== null;
  const hasBadAuth = searchParams.get('badauth') !== null;

  const resetErrors = () => {
    setIsEmailError(false);
    setIsPasswordError(false);
    setLoginError('');
  };

  const handleSignIn = async () => {
    resetErrors();
    if (!email) {
      setIsEmailError(true);
      return;
    }
    if (!password) {
      setIsPasswordError(true);
      return;
    }
    try {
      setCookieValue(LOGIN_STARTED, Date.now().toString(), {
        domain: url.getTLD(window.location.hostname),
        // expire the cookie in 20 mins. This is to ensure that the cookie is not set for a long time. We will ideally be deleting this cookie after redirecting to the app.
        expires: new Date(Date.now() + 1200e3).toUTCString(),
      });
      logger.track({
        event: 'LOGIN',
        properties: {
          UserEmailAddress: email.trim(),
          referrer: document.referrer,
        },
      });

      const cognitoUser = await Auth.signIn(
        email.trim().toLowerCase(),
        password
      );
      setNumSignInAttempts(0);
      setUser(cognitoUser);
    } catch (error: any) {
      if (error instanceof Error) {
        const { message } = handleCognitoError(error as ICognitoError);
        setLoginError(message);
      }
      logger.error({ event: 'LOGIN_ERROR', error });
      setNumSignInAttempts(numSignInAttempts + 1);
    }
  };

  useEffect(() => {
    sessionStorage.setItem('referrer', document.referrer);
    if (user) {
      const { challengeName } = user;
      if (challengeName === COGNITO_CHALLENGES.NEW_PASSWORD_REQUIRED) {
        navigate(`/${ROUTES.NEW_PASSWORD}`);
      } else if (challengeName === COGNITO_CHALLENGES.SOFTWARE_TOKEN_MFA) {
        navigate(`/${ROUTES.MFA}${window.location.search}`);
      }
    }
  }, [user, navigate]);

  useEffect(() => {
    if (hasTimedOut) {
      setLoginError(
        'Your session has expired. You may have logged in from another device. If you are sharing login credentials with other users, please make sure to create your own.'
      );
    } else if (hasBadAuth) {
      setLoginError(
        'The social pages you have access to are currently not connected to Echobox. Until these are reconnected, you will not be able to log in. Please contact an admin user to reconnect your pages.'
      );
    } else if (accessError) {
      setLoginError(accessError);
      saveAccessError('');
    }
  }, [accessError, hasTimedOut, saveAccessError, hasBadAuth]);

  useEffect(() => {
    const filteredCookies = filterCookies('NoPermissions');
    for (const cookie of filteredCookies) {
      deleteCookie(cookie);
    }
  }, []);

  return (
    <Box w="full">
      <Card.Title variant="h3" pb="6">
        Sign in
      </Card.Title>
      <Card.Content w="full">
        <Flex direction="column" gap="4">
          <FormControl isInvalid={isEmailError}>
            <FormControl.FormLabel>Email</FormControl.FormLabel>
            <Input
              size="lg"
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              maxLength={128}
              data-cy-input="username"
              autoFocus
            />
            {isEmailError && (
              <FormControl.FormErrorMessage>
                Enter your email
              </FormControl.FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={isPasswordError}>
            <FormControl.FormLabel mr="0">
              Password
              <Link
                as={RouterLink}
                to={`${ROUTES.FORGOT_PASSWORD}`}
                float="right"
              >
                Forgot password?
              </Link>
            </FormControl.FormLabel>
            <Input
              size="lg"
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              maxLength={128}
              data-cy-input="password"
            />
            {isPasswordError && (
              <FormControl.FormErrorMessage>
                Enter your password
              </FormControl.FormErrorMessage>
            )}
          </FormControl>
        </Flex>
        <Box my="6">
          <Button
            w="full"
            size="lg"
            onClick={handleSignIn}
            loadingText={<span>Signing in</span>}
            {...(loginError && { mb: '2' })}
            data-cy-action="signIn"
          >
            <span>Sign in</span>
          </Button>
          {loginError && (
            <ErrorMessage>
              {loginError}{' '}
              {numSignInAttempts > MAX_LOGIN_ATTEMPTS_WITHOUT_HELP && (
                <>
                  Please try again and if it still doesn&apos;t work,{' '}
                  <Button
                    as={Link}
                    href="mailto:support@echobox.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="link"
                  >
                    contact us
                  </Button>
                  .
                </>
              )}
            </ErrorMessage>
          )}
        </Box>
        <Flex alignItems="center" gap="2">
          <Divider color="gray.400" />
          <Text size="xs" color="gray.600" whiteSpace="nowrap">
            New to Echobox?
          </Text>
          <Divider color="gray.400" />
        </Flex>
        <Button
          size="lg"
          variant="secondary"
          mt="6"
          onClick={() =>
            window
              .open('https://www.echobox.com/contact-sales', '_blank')
              ?.focus()
          }
        >
          Get in touch for a demo
        </Button>
      </Card.Content>
    </Box>
  );
};

export default Login;
