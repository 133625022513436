import Root from 'layouts/Root';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';

import * as API from 'api/api';

import { ROUTES } from 'constants/constants';

import Login from 'screens/Login';
import ForgotPassword from 'screens/ForgotPassword';
import ResetPassword from 'screens/ResetPassword';
import NewPassword from 'screens/NewPassword';
import MFA from 'screens/MFA';
import ErrorBoundary from 'components/ErrorBoundary';

const UnauthenticatedApp = () => {
  const router = createBrowserRouter([
    {
      path: ROUTES.HOME,
      element: <Root />,
      loader: async () => {
        const statusPageNotification = await API.getStatusPageNotification();
        return statusPageNotification;
      },
      children: [
        {
          index: true,
          element: (
            <ErrorBoundary
              logglyProperties={{
                location: 'UnauthenticatedApp/Login',
              }}
            >
              <Login />
            </ErrorBoundary>
          ),
        },
        {
          path: ROUTES.LOGIN,
          element: (
            <ErrorBoundary
              logglyProperties={{
                location: 'UnauthenticatedApp/Login',
              }}
            >
              <Login />
            </ErrorBoundary>
          ),
        },
        {
          path: ROUTES.NEW_PASSWORD,
          element: (
            <ErrorBoundary
              logglyProperties={{
                location: 'UnauthenticatedApp/NewPassword',
              }}
            >
              <NewPassword />
            </ErrorBoundary>
          ),
        },
        {
          path: ROUTES.MFA,
          element: <MFA />,
        },
        {
          path: ROUTES.FORGOT_PASSWORD,
          element: <ForgotPassword />,
        },
        {
          path: ROUTES.RESET_PASSWORD,
          element: (
            <ErrorBoundary
              logglyProperties={{
                location: 'UnauthenticatedApp/ResetPassword',
              }}
            >
              <ResetPassword />
            </ErrorBoundary>
          ),
        },
        {
          path: '*',
          element: <Navigate to={ROUTES.LOGIN} />,
        },
      ],
    },
  ]);
  return <RouterProvider router={router} />;
};

export default UnauthenticatedApp;
