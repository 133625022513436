import { ICognitoError } from '../types/types';
import {
  API_ERROR_TYPES,
  COGNITO_ERRORS,
  COGNITO_ERROR_MESSAGES,
} from '../constants/constants';

function handleCognitoError(error: ICognitoError) {
  const newError = {
    ...error,
    type: API_ERROR_TYPES.API_HTTP_COGNITO,
    message:
      COGNITO_ERROR_MESSAGES[error?.code] ||
      error.message ||
      'Something went wrong.',
  };

  if (
    newError.code === COGNITO_ERRORS.NOT_AUTHORIZED ||
    newError.code === COGNITO_ERRORS.USER_NOT_FOUND
  ) {
    if (error?.message?.includes('session is expired')) {
      newError.message = 'Session expired. Please login again.';
    } else newError.message = 'Invalid email or password.';
  }
  return newError;
}

function handleExternalError(error: Error) {
  const newError = {
    ...error,
    type: API_ERROR_TYPES.API_HTTP_EXTERNAL,
    message: error.message ?? 'Something went wrong. Please contact support.',
  };
  return newError;
}

function stringifyError(
  error: Error,
  filter?: () => void,
  space?: string | number | undefined
) {
  const plainObject: { [key: string]: Error[keyof typeof error] } = {};
  Object.getOwnPropertyNames(error).forEach(key => {
    plainObject[key] = error[key as keyof typeof error];
  });
  return JSON.stringify(plainObject, filter, space);
}

function getErrorMessage(error: Error | string) {
  if (!error) {
    return null;
  }
  if (typeof error === 'string' || error instanceof String) {
    return error;
  }

  if (error instanceof Error) {
    return error.message;
  }

  return '';
}

function stringifyReport(report: object) {
  const stringified = JSON.parse(JSON.stringify(report));

  // Stringify any Arguments entries that are objects
  // This includes things like Arguments.accountAPIIds, Arguments.identifiers,
  // Arguments.permissionsOnProperty and Arguments.propertyURNs which can account for
  // a significant number of different fields
  if (stringified.Arguments) {
    Object.keys(stringified.Arguments).forEach(arg => {
      if (typeof stringified.Arguments[arg] === 'object') {
        try {
          stringified.Arguments[arg] = JSON.stringify(
            stringified.Arguments[arg]
          );
        } catch (e) {
          //
        }
      }
    });
  }

  // Stringify any Error.config and Error.response.config/headers/request entries
  ['Error', 'OriginalError'].forEach(key => {
    if (stringified[key]) {
      if (stringified[key].config) {
        try {
          stringified[key].config = JSON.stringify(stringified[key].config);
        } catch (e) {
          //
        }
      }
      if (stringified[key].response) {
        if (stringified[key].response.config) {
          try {
            stringified[key].response.config = JSON.stringify(
              stringified[key].response.config
            );
          } catch (e) {
            //
          }
        }
        if (stringified[key].response.headers) {
          try {
            stringified[key].response.headers = JSON.stringify(
              stringified[key].response.headers
            );
          } catch (e) {
            //
          }
        }
        if (stringified[key].response.request) {
          try {
            stringified[key].response.request = JSON.stringify(
              stringified[key].response.request
            );
          } catch (e) {
            //
          }
        }
      }
    }
  });

  return stringified;
}

export {
  handleCognitoError,
  handleExternalError,
  stringifyError,
  getErrorMessage,
  stringifyReport,
};
