import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';

import {
  APPS,
  LAST_USED_PRODUCT_KEY,
  LOGIN_STARTED,
  NO_PERMISSIONS_PREFIX,
} from 'constants/constants';
import * as environment from 'utils/environment';
import * as cookie from 'utils/cookie';
import * as logger from 'utils/logger';
import { useAuth } from 'context/authContext';
import { getHost, getTLD, isValidRedirect, navigateTo } from 'utils/url';

const currentHostType = environment.getHostType();

const NavigateToApp = () => {
  const {
    user: { signInUserSession },
    setUser,
    accessError,
    saveAccessError,
  } = useAuth();
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [isError, setIsError] = useState(false);
  const accessGroups = signInUserSession?.idToken?.payload?.['cognito:groups'];
  const email = signInUserSession?.idToken?.payload?.email;

  useEffect(() => {
    if (cookie.getCookieValue(LOGIN_STARTED)) {
      const loginStarted = Number(cookie.getCookieValue(LOGIN_STARTED));
      const loginTime = Date.now() - loginStarted;
      logger.track({
        event: 'Login Time - Login App',
        properties: {
          runTimeMS: loginTime,
          UserEmailAddress: email,
        },
      });
      // set email in a cookie so that we can read it in Social and Newsletters and add them to loggly events
      cookie.setCookieValue('email', email, {
        domain: getTLD(window.location.hostname),
        // expire the cookie in 20 mins. This is to ensure that the cookie is not set for a long time. We will ideally be deleting this cookie after redirecting to the app.
        expires: new Date(Date.now() + 1200e3).toUTCString(),
      });
    }
    saveAccessError('');
    const { referrer } = document;
    logger.info(`Authenticated App: checking referrer: ${referrer}`);
    if (
      referrer &&
      cookie.getCookieValue(`${NO_PERMISSIONS_PREFIX}${getHost(referrer)}`)
    ) {
      logger.error({
        event: 'NO_PERMISSIONS',
        properties: {
          UserEmailAddress: email,
          referrer,
        },
      });

      if (isValidRedirect(sessionStorage.getItem('referrer'))) {
        saveAccessError('You do not have access to this application.');
        return;
      }
    } else if (isValidRedirect(referrer)) {
      // redirect to referrer if valid
      logger.info(`Authenticated App: redirecting to referrer: ${referrer}`);
      setIsRedirecting(true);
      navigateTo(`${referrer}${window.location.search}`);
      return;
    }

    // redirect to ebx last used product cookie if valid
    const lastUsedProduct = cookie.getCookieValue(LAST_USED_PRODUCT_KEY);
    if (
      isValidRedirect(lastUsedProduct) &&
      !cookie.getCookieValue(
        `${NO_PERMISSIONS_PREFIX}${getHost(lastUsedProduct as string)}`
      )
    ) {
      logger.info(
        `Authenticated App: redirecting to last used product: ${lastUsedProduct}`
      );
      setIsRedirecting(true);
      navigateTo(lastUsedProduct as string);
      return;
    }

    for (const app of APPS) {
      const appUrl = app.urls[currentHostType];
      let appUrlHost = '';
      try {
        appUrlHost = new URL(appUrl).host;
      } catch (error) {
        logger.error({
          event: 'INVALID_APP_URL',
          properties: {
            UserEmailAddress: email,
          },
        });
      }

      if (
        accessGroups.includes(app.cognitoGroup) &&
        !cookie.getCookieValue(`${NO_PERMISSIONS_PREFIX}${appUrlHost}`)
      ) {
        logger.info(`Authenticated App: redirecting to ${appUrl}`);
        setIsRedirecting(true);
        navigateTo(appUrl);
        return;
      }
    }
    logger.error({
      event: 'NO_PERMISSIONS_IN_ANY_APP',
      properties: {
        UserEmailAddress: email,
        referrer,
      },
    });
    saveAccessError(
      'Your login was successful but you do not have access to any active Echobox products. If you feel this is incorrect, please contact your administrator or Echobox support for help.'
    );
  }, [accessGroups, email, saveAccessError]);

  useEffect(() => {
    if (accessError) {
      setIsError(true);
    }
  }, [accessError]);

  if (!isRedirecting && isError) {
    Auth.signOut();
    // clear last used product as it threw an error
    cookie.deleteCookie(LAST_USED_PRODUCT_KEY);
    // reloading the page to clear document.referrer on error
    window.location.href = '/';
    setUser(null);
  }

  return null;
};

export default NavigateToApp;
