import { useToast } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useCallback } from 'react';

const useNotification = () => {
  const toast = useToast();

  const errorNotification = useCallback(
    (title: string, description?: string) => {
      toast({
        title,
        description,
        variant: 'error',
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const successNotification = useCallback(
    (title: string, description?: string) => {
      toast({
        title,
        description,
        variant: 'success',
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return { errorNotification, successNotification };
};

export default useNotification;
