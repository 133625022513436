import {
  Box,
  Center,
  Spinner,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { QRCodeSVG } from 'qrcode.react';

import { useAuth } from 'context/authContext';

const RegisterMFA = ({
  code,
  error,
}: {
  code: string | null;
  error: string | null;
}) => {
  const { user } = useAuth();
  const emailAddress = user?.attributes?.email;
  const qrCode = `otpauth://totp/${emailAddress}?secret=${code}&issuer=Echobox`;

  const isLoading = !code && !error;

  return (
    <Box textAlign="center">
      <Text>
        Scan this QR code with an authenticator app like Google Authenticator.
      </Text>
      <Text>It can be downloaded from App Store or Play Store.</Text>
      <Center h={64} m={5}>
        {code && <QRCodeSVG id="mfa-qr-code" value={qrCode} size={256} />}
        {error && <Text color="red.600">{error}</Text>}
        {isLoading && <Spinner size="lg" />}
      </Center>
    </Box>
  );
};

export default RegisterMFA;
