import { Auth } from 'aws-amplify';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { ChildrenType, FixTypeLater } from 'types/types';
import FullPageSpinner from 'components/FullPageSpinner';
import * as logger from 'utils/logger';
import * as tracker from 'utils/tracker';

interface AuthContextType {
  user: FixTypeLater;
  setUser: (user: FixTypeLater) => void;
  accessError: string;
  saveAccessError: (accessError: string) => void;
  isStaffUser: boolean;
  refreshUser: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);
AuthContext.displayName = 'AuthContext';

const AuthProvider = ({ children }: ChildrenType) => {
  const [user, setUser] = useState<FixTypeLater>(null);
  const [accessError, setAccessError] = useState(
    sessionStorage.getItem('accessError') || ''
  );
  const [isLoading, setIsLoading] = useState(true);

  const accessGroups =
    user?.signInUserSession?.idToken?.payload?.['cognito:groups'];
  const isStaffUser = accessGroups?.includes('STAFF_USER');

  useEffect(() => {
    // fetch user
    async function fetchUser() {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser({
          bypassCache: true,
        });
        setUser(cognitoUser);
        tracker.setProfile(cognitoUser);
      } catch (error: any) {
        logger.info(`authContext: Failed to fetch user: ${error}`);
      } finally {
        setIsLoading(false);
      }
    }
    fetchUser();
  }, []);

  const saveAccessError = useCallback(
    (error: string) => {
      setAccessError(error);
      sessionStorage.setItem('accessError', error);
    },
    [setAccessError]
  );

  const refreshUser = useCallback(async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      setUser(cognitoUser);
    } catch (error: any) {
      logger.info(`authContext: Failed to refresh user: ${error}`);
    }
  }, []);

  const value = useMemo(
    () => ({
      user,
      setUser,
      accessError,
      saveAccessError,
      isStaffUser,
      refreshUser,
    }),
    [user, setUser, accessError, saveAccessError, isStaffUser, refreshUser]
  );

  if (isLoading) return <FullPageSpinner />;

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useContext must be used within AuthProvider`);
  }
  return context;
}

export { AuthProvider, useAuth };
