import { API_TIMEOUTS, HOST_TYPES } from 'constants/constants';
import { client } from 'utils/api';
import * as environment from 'utils/environment';
import pkg from '../../package.json';

export default async function getVersion() {
  const dev = environment.getHostType() === HOST_TYPES.DEV;
  if (dev) {
    return pkg.version;
  }
  try {
    const response = await client<{ version: string }>(
      `${window.location.protocol}//${window.location.host}`,
      'package.json',
      { timeout: API_TIMEOUTS.XXS }
    );
    return response.version;
  } catch (error) {
    return '0.0.0';
  }
}
