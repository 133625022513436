import { HOST_TYPES } from './constants';

export const mixpanelConfig = {
  [HOST_TYPES.PROD]: {
    token: 'edee7338c7eac6d82d4f399da8f987d2',
  },
  [HOST_TYPES.STAGING]: {
    token: '2dbb3ca2d491c5d6a412e4fe0dbb36c9',
  },
};
