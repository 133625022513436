import { Alert, Text, useToken } from '@ebx-ui/ebx-ui-component-library-sdk';
import createDOMPurify from 'dompurify';

const StatusPageNotification = ({ message }: { message: string }) => {
  const [primary600] = useToken('colors', ['primary.600']);
  const DOMPurify = createDOMPurify(window);
  const updatedMessage = `${message} For more information please see <span>
  <a class="status-notification" href="https://status.echobox.com/" style="color: ${primary600};" target="blank" rel="noopener noreferrer">
  <b>status.echobox.com</b></a></span>.`;

  return (
    <Alert>
      <Alert.Title>
        <Text
          lineHeight={1.4}
          fontSize="13px"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(updatedMessage),
          }}
        />
      </Alert.Title>
    </Alert>
  );
};

export default StatusPageNotification;
