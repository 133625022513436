import { APPS, HOST_TYPES } from 'constants/constants';
import * as environment from './environment';

function getTLD(domain: string) {
  const parts = domain.split('.').reverse();
  return parts[1] ? `${parts[1]}.${parts[0]}` : parts[0];
}

function getHost(url: string) {
  const { host } = new URL(url);
  return host;
}

function isValidRedirect(url: string | undefined | null) {
  if (!url || url.includes(window.location.host)) {
    return false;
  }

  const hostType = environment.getHostType();

  let urlOrigin = url;
  try {
    urlOrigin = new URL(url)?.origin;
  } catch (error) {
    return false;
  }

  return (
    APPS.some(app => app.urls[hostType] === urlOrigin) ||
    hostType === HOST_TYPES.STAGING ||
    hostType === HOST_TYPES.DEV
  );
}

function navigateTo(url: string) {
  window.location.href = url;
}

export { getTLD, getHost, isValidRedirect, navigateTo };
