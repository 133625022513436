import {
  Box,
  Flex,
  FormControl,
  Heading,
  Input,
  Text,
  useDisclosure,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useCallback, useEffect, useState } from 'react';

import { ROUTES } from 'constants/constants';
import { useAuth } from 'context/authContext';
import * as logger from 'utils/logger';
import {
  resetSettingsState,
  setSettingsChanged,
  setSettingsError,
  setSettingsSaving,
  updateOnSave,
  useSettingsContext,
} from 'context/settingsContext';
import SettingsWrapper from 'layouts/Settings';
import { Auth } from 'aws-amplify';
import { handleCognitoError } from 'utils/errors';
import { ICognitoError } from 'types/types';
import VerifyEmailModal from 'components/VerifyEmailModal';
import useNotification from 'hooks/useNotification';
import * as tracker from 'utils/tracker';

const PersonalDetails = () => {
  const { user, isStaffUser, refreshUser } = useAuth();
  const [name, setName] = useState(user?.attributes?.name || '');
  const [email, setEmail] = useState(user?.attributes?.email || '');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { dispatch } = useSettingsContext();

  const hasNameChanged = name !== user?.attributes?.name;
  const hasEmailChanged = email !== user?.attributes?.email;

  const { errorNotification, successNotification } = useNotification();

  const handleSave = useCallback(
    async (newName: string, newEmail: string) => {
      setSettingsSaving(dispatch);

      const userAttributes = {
        ...(hasNameChanged && { name: newName }),
        ...(hasEmailChanged && { email: newEmail }),
      };

      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(currentUser, userAttributes);
        if (hasNameChanged) {
          successNotification(
            'Name updated',
            'Your name has been updated successfully'
          );
        }
        if (hasEmailChanged) {
          onOpen();
        }
        tracker.track({
          eventName: 'Change Personal Details',
          trackingParams: {
            'Name Before': user?.attributes?.name,
            'Email Before': user?.attributes?.email,
          },
        });
        refreshUser();
      } catch (error: any) {
        if (error instanceof Error) {
          const { message } = handleCognitoError(error as ICognitoError);
          errorNotification(message);
        }
        logger.error({ event: 'UPDATE_USER_DETAILS', error });
      }
    },
    [
      dispatch,
      errorNotification,
      hasEmailChanged,
      hasNameChanged,
      onOpen,
      refreshUser,
      successNotification,
      user,
    ]
  );

  const handleEmailChange = (value: string) => {
    setEmail(value);
    // check if email is valid
    if (!value || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      setEmailErrorMessage('Please enter a valid email address ');
    } else {
      setEmailErrorMessage('');
    }
  };

  const handleClose = () => {
    onClose();
    setEmail(user?.attributes?.email || '');
  };

  const handleEmailChangeSuccess = async () => {
    successNotification(
      'Email address updated',
      'Your email address has been updated successfully'
    );
    onClose();
    refreshUser();
  };

  useEffect(() => {
    if (!name || emailErrorMessage) {
      setSettingsError(dispatch);
    } else if (!hasNameChanged && !hasEmailChanged) {
      resetSettingsState(dispatch);
    } else {
      setSettingsChanged(dispatch);
      updateOnSave(dispatch, () => handleSave(name, email));
    }
  }, [
    dispatch,
    email,
    emailErrorMessage,
    handleSave,
    hasEmailChanged,
    hasNameChanged,
    name,
  ]);

  // reset settings state on unmount
  useEffect(() => {
    return () => resetSettingsState(dispatch);
  }, [dispatch]);

  return (
    <SettingsWrapper
      routes={[
        { label: 'Profile', link: `/${ROUTES.MY_PROFILE}` },
        {
          label: 'Personal details',
          link: `/${ROUTES.PERSONAL_DETAILS}`,
        },
      ]}
    >
      <Flex flexDir="column" gap={6} align="flex-start">
        <Box>
          <Heading variant="h2">Personal details</Heading>
          <Text size="sm" color="gray.600" mt={2}>
            Edit your name {isStaffUser ? '' : 'and email'}
          </Text>
        </Box>
        <Flex flexDir="column" gap={5} minW="420px">
          <FormControl isInvalid={!name}>
            <FormControl.FormLabel mr="0">Full name</FormControl.FormLabel>
            <Input
              type="text"
              value={name}
              onChange={e => setName(e.target.value)}
              maxLength={128}
            />
            {!name && (
              <FormControl.FormErrorMessage>
                Please enter a name
              </FormControl.FormErrorMessage>
            )}
          </FormControl>
          {!isStaffUser && (
            <FormControl isInvalid={Boolean(emailErrorMessage)}>
              <FormControl.FormLabel>Email address</FormControl.FormLabel>
              <Input
                type="email"
                value={email}
                onChange={e => handleEmailChange(e.target.value)}
                maxLength={128}
              />
              {emailErrorMessage && (
                <FormControl.FormErrorMessage>
                  {emailErrorMessage}
                </FormControl.FormErrorMessage>
              )}
            </FormControl>
          )}
        </Flex>
      </Flex>
      {isOpen && (
        <VerifyEmailModal
          emailAddress={email}
          onClose={handleClose}
          onSuccess={handleEmailChangeSuccess}
          onError={errorNotification}
        />
      )}
    </SettingsWrapper>
  );
};

export default PersonalDetails;
