import { useEffect, useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  Flex,
  FormControl,
  Input,
  Link,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import PasswordRules from 'components/PasswordRules';
import { ROUTES } from 'constants/constants';
import { Auth } from 'aws-amplify';
import { handleCognitoError } from 'utils/errors';
import { ICognitoError } from 'types/types';
import ErrorMessage from 'components/ErrorMessage';
import * as logger from 'utils/logger';

const ResetPassword = () => {
  const [email] = useState(sessionStorage.getItem('email') || '');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [code, setCode] = useState('');
  const [resetError, setResetError] = useState('');

  const navigate = useNavigate();

  const containsUpperCase = /(?=.*[A-Z])/.test(password);
  const containsLowerCase = /(?=.*[a-z])/.test(password);
  const containsNumberCharacter = /^(?=.*[0-9])/.test(password);
  const containsTenCharacters = password.length >= 10;

  const canSave =
    containsUpperCase &&
    containsLowerCase &&
    containsNumberCharacter &&
    containsTenCharacters;

  const handleReset = async () => {
    setResetError('');
    try {
      await Auth.forgotPasswordSubmit(email, code, password);
      sessionStorage.removeItem('email');
      await Auth.signOut({ global: true });
      navigate(ROUTES.HOME);
    } catch (error: any) {
      if (error instanceof Error) {
        const { message } = handleCognitoError(error as ICognitoError);
        setResetError(message);
      }
      logger.error({ event: 'RESET_PASSWORD_ERROR', error });
    }
  };

  useEffect(() => {
    if (!email) {
      navigate(`/${ROUTES.FORGOT_PASSWORD}`);
    }
  }, [email, navigate]);

  return (
    <>
      <Card.Title variant="h3" pb="6">
        Set your new password
      </Card.Title>
      <Card.Content w="full">
        <Flex direction="column" gap="4">
          <Box>
            <FormControl>
              <FormControl.FormLabel mr="0">
                New password
                <Link
                  float="right"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? 'Hide' : 'Show'} password
                </Link>
              </FormControl.FormLabel>
              <Input
                size="lg"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={e => setPassword(e.target.value)}
                maxLength={128}
                autoFocus
              />
            </FormControl>
            <PasswordRules
              containsUpperCase={containsUpperCase}
              containsLowerCase={containsLowerCase}
              containsNumberCharacter={containsNumberCharacter}
              containsTenCharacters={containsTenCharacters}
              mb="4"
            />
          </Box>
          <FormControl>
            <FormControl.FormLabel mr="0">
              Confirmation code
              <Link
                as={RouterLink}
                to={`/${ROUTES.FORGOT_PASSWORD}`}
                float="right"
              >
                Request new code
              </Link>
            </FormControl.FormLabel>
            <Input
              size="lg"
              type="password"
              value={code}
              onChange={e => setCode(e.target.value)}
              maxLength={128}
            />
          </FormControl>
        </Flex>
        <Button
          w="full"
          size="lg"
          mt="6"
          isDisabled={!canSave || !code}
          onClick={handleReset}
        >
          Reset your password
        </Button>
        {resetError && <ErrorMessage>{resetError}</ErrorMessage>}
      </Card.Content>
    </>
  );
};

export default ResetPassword;
