import { SETTINGS_ACTION_BUTTON_STATUS } from '@ebx-ui/ebx-ui-component-library-sdk';
import * as React from 'react';

type SettingsActionButtonStatus = keyof typeof SETTINGS_ACTION_BUTTON_STATUS;

const ACTION_TYPE = {
  RESET: 'RESET',
  UPDATE_ON_SAVE: 'UPDATE_ON_SAVE',
  SETTINGS_CHANGED: 'SETTINGS_CHANGED',
  SETTINGS_UNCHANGED: 'SETTINGS_UNCHANGED',
  SETTINGS_ERROR: 'SETTINGS_ERROR',
  SETTINGS_SAVING: 'SETTINGS_SAVING',
} as const;

interface SettingsContextInterface {
  actionButtonStatus: SettingsActionButtonStatus;
  actionButtonSaveText: string;
  onSave?: () => void;
  dispatch: React.Dispatch<ACTION>;
}

type ACTION =
  | { type: typeof ACTION_TYPE.RESET }
  | { type: typeof ACTION_TYPE.SETTINGS_CHANGED }
  | { type: typeof ACTION_TYPE.SETTINGS_UNCHANGED }
  | { type: typeof ACTION_TYPE.SETTINGS_ERROR }
  | { type: typeof ACTION_TYPE.SETTINGS_SAVING }
  | {
      type: typeof ACTION_TYPE.UPDATE_ON_SAVE;
      payload?: SettingsContextInterface['onSave'];
    };

const SettingsContext = React.createContext<SettingsContextInterface | null>(
  null
);
SettingsContext.displayName = 'SettingsContext';

const useSettingsContext = () => {
  const settingsContext = React.useContext(SettingsContext);

  if (settingsContext === undefined) {
    throw new Error(
      'useSettingsContext must be used within a SettingsProvider'
    );
  }
  if (settingsContext === null) {
    throw new Error('SettingsContext must be initialized by the Provider');
  }

  return settingsContext;
};

const initialState: Omit<SettingsContextInterface, 'dispatch'> = {
  actionButtonStatus: SETTINGS_ACTION_BUTTON_STATUS.STATUS_UNCHANGED,
  actionButtonSaveText: 'Save changes',
  onSave: undefined,
};

function reducer(state: typeof initialState, action: ACTION) {
  switch (action.type) {
    case ACTION_TYPE.RESET:
      return { ...initialState };
    case ACTION_TYPE.UPDATE_ON_SAVE:
      return { ...state, onSave: action.payload };
    case ACTION_TYPE.SETTINGS_CHANGED:
      return {
        ...state,
        actionButtonStatus: SETTINGS_ACTION_BUTTON_STATUS.STATUS_CHANGED,
      };
    case ACTION_TYPE.SETTINGS_UNCHANGED:
      return {
        ...state,
        actionButtonStatus: SETTINGS_ACTION_BUTTON_STATUS.STATUS_UNCHANGED,
      };
    case ACTION_TYPE.SETTINGS_ERROR:
      return {
        ...state,
        actionButtonStatus: SETTINGS_ACTION_BUTTON_STATUS.STATUS_ERROR,
      };
    case ACTION_TYPE.SETTINGS_SAVING:
      return {
        ...state,
        actionButtonStatus: SETTINGS_ACTION_BUTTON_STATUS.STATUS_SAVING,
      };
    default: {
      const unrecognisedActionType = (action as any).type;
      throw new Error(`Unhandled action type: ${unrecognisedActionType}`);
    }
  }
}

interface SettingsProviderProps {
  children: React.ReactNode;
}

const SettingsProvider = ({ children }: SettingsProviderProps) => {
  const [{ actionButtonSaveText, actionButtonStatus, onSave }, dispatch] =
    React.useReducer(reducer, initialState);

  const settingsValue = React.useMemo(() => {
    return {
      actionButtonStatus,
      actionButtonSaveText,
      onSave,
      dispatch,
    };
  }, [actionButtonSaveText, actionButtonStatus, onSave]);

  return (
    <SettingsContext.Provider value={settingsValue}>
      {children}
    </SettingsContext.Provider>
  );
};

function resetSettingsState(dispatch: React.Dispatch<ACTION>) {
  dispatch({ type: ACTION_TYPE.RESET });
}
function setSettingsChanged(dispatch: React.Dispatch<ACTION>) {
  dispatch({ type: ACTION_TYPE.SETTINGS_CHANGED });
}
function setSettingsUnchanged(dispatch: React.Dispatch<ACTION>) {
  dispatch({ type: ACTION_TYPE.SETTINGS_UNCHANGED });
}
function setSettingsError(dispatch: React.Dispatch<ACTION>) {
  dispatch({ type: ACTION_TYPE.SETTINGS_ERROR });
}
function setSettingsSaving(dispatch: React.Dispatch<ACTION>) {
  dispatch({ type: ACTION_TYPE.SETTINGS_SAVING });
}
function updateOnSave(
  dispatch: React.Dispatch<ACTION>,
  onSave?: SettingsContextInterface['onSave']
) {
  dispatch({ type: ACTION_TYPE.UPDATE_ON_SAVE, payload: onSave });
}

export {
  SettingsProvider,
  resetSettingsState,
  setSettingsChanged,
  setSettingsError,
  setSettingsSaving,
  setSettingsUnchanged,
  updateOnSave,
  useSettingsContext,
};
