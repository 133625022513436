import { Button, Modal } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useEffect, useRef, useState } from 'react';

import { Auth } from 'aws-amplify';
import { handleCognitoError } from 'utils/errors';
import { ICognitoError } from 'types/types';
import { PREFERRED_MFA_TYPES } from 'constants/constants';
import * as logger from 'utils/logger';
import * as tracker from 'utils/tracker';
import useNotification from 'hooks/useNotification';
import { useAuth } from 'context/authContext';

import Steps from './Steps';
import RegisterMFA from './RegisterMFA';
import VerifyMFA from './VerifyMFA';

const { Step } = Steps;

const steps = ['Register Echobox', 'Verify Token'];

type StepIndex = 0 | 1;

const SetupMFAModal = ({ onClose }: { onClose: () => void }) => {
  const { refreshUser } = useAuth();
  const [activeStep, setActiveStep] = useState<StepIndex>(0);
  const [code, setCode] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [userInput, setUserInput] = useState<string>('');
  const { successNotification } = useNotification();
  const verify = useRef<HTMLButtonElement | null>(null);

  const getStepContent = (stepIndex: 0 | 1) => {
    const stepContent = {
      0: <RegisterMFA code={code} error={error} />,
      1: (
        <VerifyMFA handleChange={value => setUserInput(value)} error={error} />
      ),
    };
    return stepContent[stepIndex] ?? 'Unknown step';
  };

  const handleNext = () => {
    setActiveStep(1);
  };

  const handleBack = () => {
    setError(null);
    setActiveStep(0);
  };

  const handleVerify = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      // Auth.verifyTotpToken returns an object with a Status property but the type says it returns a CognitoUserSession
      // Probably a bug in our amplify version
      // @ts-expect-error
      const { Status } = await Auth.verifyTotpToken(user, userInput);
      if (Status === 'SUCCESS') {
        const response = await Auth.setPreferredMFA(
          user,
          PREFERRED_MFA_TYPES.TOTP
        );
        if (response === 'SUCCESS') {
          refreshUser();
          tracker.track({
            eventName: 'Enable MFA',
          });
          successNotification(
            "You're all set!",
            'From now on, you will use your password and a verification code to log in to Echobox'
          );
          onClose();
        }
      }
    } catch (e: any) {
      if (e instanceof Error) {
        const { message } = handleCognitoError(e as ICognitoError);
        setError(message);
      }
      logger.error({ event: 'VERIFY_MFA_CODE', error: e });
    }
  };

  useEffect(() => {
    const generateCode = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const mfaCode = await Auth.setupTOTP(user);
        setCode(mfaCode);
      } catch (e: any) {
        if (e instanceof Error) {
          const { message } = handleCognitoError(e as ICognitoError);
          setError(message);
        }
        logger.error({ event: 'GENERATE_MFA_CODE', error: e });
      }
    };
    generateCode();
  }, []);

  useEffect(() => {
    if (userInput?.length === 6) {
      verify?.current?.click();
    }
  }, [userInput?.length]);

  return (
    <Modal isOpen onClose={onClose}>
      <Modal.Header>
        <Steps activeStep={activeStep}>
          {steps.map((step, i) => {
            return (
              <Step index={i} key={step}>
                {step}
              </Step>
            );
          })}
        </Steps>
      </Modal.Header>
      <Modal.Body color="gray.900">{getStepContent(activeStep)}</Modal.Body>
      <Modal.Footer>
        {activeStep === 0 && (
          <>
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={handleNext} isDisabled={!code}>
              Next
            </Button>
          </>
        )}
        {activeStep === 1 && (
          <>
            <Button variant="secondary" onClick={handleBack}>
              Back
            </Button>
            <Button
              ref={verify}
              onClick={handleVerify}
              isDisabled={userInput?.length < 6}
            >
              Verify
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default SetupMFAModal;
