import axios, { AxiosRequestConfig } from 'axios';
import { API_TIMEOUTS } from 'constants/constants';

interface Request extends AxiosRequestConfig {
  data?: object | null;
  token?: string;
}

async function client<T = unknown>(
  apiUrl: string,
  endpoint: string,
  { data, headers, ...customConfig }: Request = {}
): Promise<T> {
  const config = {
    url: `${apiUrl}/${endpoint}`,
    method: data ? 'POST' : 'GET',
    timeout: API_TIMEOUTS.S,
    data: data ? JSON.stringify(data) : undefined,
    headers,
    ...customConfig,
  };
  return axios(config).then(async response => {
    if (response.status === 200) {
      return response.data as T;
    }
    return Promise.reject(data);
  });
}

export { client };
