import { HOST_TYPES } from 'constants/constants';
import { getTLD } from './url';

function getHostType(hostname?: string | null) {
  const tld = hostname ? getTLD(hostname) : getTLD(window.location.hostname);
  switch (tld) {
    case 'echobox.dev':
      return HOST_TYPES.STAGING;
    case 'echobox.com':
      return HOST_TYPES.PROD;
    default:
      return HOST_TYPES.DEV;
  }
}

function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

function isDev() {
  return getHostType() === HOST_TYPES.DEV;
}

export { getHostType, isSafari, isDev };
