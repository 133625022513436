import {
  Box,
  HStack,
  PinInput,
  PinInputField,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';

const VerifyMFA = ({
  handleChange,
  error,
}: {
  handleChange: (value: string) => void;
  error: string | null;
}) => {
  return (
    <Box textAlign="center">
      <Text mb="5">
        Enter six digit code from your authenticator app to verify your account.
      </Text>
      <HStack justifyContent="center" gap="2" borderColor="gray.300">
        <PinInput
          onChange={value => handleChange(value)}
          placeholder=""
          errorBorderColor="red.600"
          focusBorderColor="primary.600"
          isInvalid={Boolean(error)}
          autoFocus
        >
          {[...Array(6)].map(index => (
            <PinInputField
              key={index}
              {...(Boolean(error) && { color: 'red.600' })}
            />
          ))}
        </PinInput>
      </HStack>
      <Text mt="4" color="red.600">
        {error}
      </Text>
    </Box>
  );
};

export default VerifyMFA;
