import {
  Card,
  Flex,
  Heading,
  SettingsCard,
  Toggle,
  useDisclosure,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { Link } from 'react-router-dom';

import { ROUTES } from 'constants/constants';
import { useAuth } from 'context/authContext';
import SettingsWrapper from 'layouts/Settings';
import { useEffect, useState } from 'react';
import SetupMFAModal from 'components/mfa/SetupMFAModal';
import { COGNITO_CHALLENGES } from 'constants/amplify';
import TurnOffMFAModal from 'components/mfa/TurnOffMFAModal';

const MyProfile = () => {
  const { user, isStaffUser } = useAuth();
  const [isMFAToggleEnabled, setIsMFAToggleEnabled] = useState(
    user?.preferredMFA === COGNITO_CHALLENGES?.SOFTWARE_TOKEN_MFA
  );
  const {
    isOpen: isSetupMFAOpen,
    onOpen: onSetupMFAOpen,
    onClose: onSetupMFAClose,
  } = useDisclosure();

  const {
    isOpen: isTurnOffMFAOpen,
    onOpen: onTurnOffMFAOpen,
    onClose: onTurnOffMFAClose,
  } = useDisclosure();

  useEffect(() => {
    setIsMFAToggleEnabled(
      user?.preferredMFA === COGNITO_CHALLENGES?.SOFTWARE_TOKEN_MFA
    );
  }, [user]);

  return (
    <SettingsWrapper
      routes={[{ label: 'Profile', link: `/${ROUTES.MY_PROFILE}` }]}
    >
      <Flex direction="column" gap="6" w="full">
        <Heading variant="h2">My Profile</Heading>
        <Flex direction="column" gap="2" w="full">
          <SettingsCard href={`/${ROUTES.PERSONAL_DETAILS}`} linkAs={Link}>
            <SettingsCard.Main
              title="Personal details"
              description={`Edit your name ${isStaffUser ? '' : 'and email address'}`}
            />
            <SettingsCard.Controls />
          </SettingsCard>
          {!isStaffUser && (
            <SettingsCard href={`/${ROUTES.PASSWORD}`} linkAs={Link}>
              <SettingsCard.Main
                title="Password"
                description="Update Password"
              />
              <SettingsCard.Controls />
            </SettingsCard>
          )}
          <Card justifyContent="space-between">
            <Card.Content>
              <Card.Title>Two-Factor Authentication (2FA)</Card.Title>
              <Card.Description>
                With Two-Factor Authentication, you&apos;ll protect your account
                with both your password and your phone
              </Card.Description>
            </Card.Content>
            <Toggle
              isChecked={isMFAToggleEnabled}
              onChange={isMFAToggleEnabled ? onTurnOffMFAOpen : onSetupMFAOpen}
            >
              {isMFAToggleEnabled ? 'On' : 'Off'}
            </Toggle>
          </Card>
        </Flex>
      </Flex>
      {isSetupMFAOpen && <SetupMFAModal onClose={onSetupMFAClose} />}
      {isTurnOffMFAOpen && <TurnOffMFAModal onClose={onTurnOffMFAClose} />}
    </SettingsWrapper>
  );
};

export default MyProfile;
