import { useEffect } from 'react';
import { Box, Card, Flex, Image } from '@ebx-ui/ebx-ui-component-library-sdk';
import { Outlet, useLoaderData, useRevalidator } from 'react-router-dom';

import background from 'assets/background.png';
import logo from 'assets/logo.svg';
import Footer from 'components/Footer';
import StatusPageNotification from 'components/StatusPageNotification';
import { STATUS_PAGE_REFRESH_INTERVAL } from 'constants/constants';

const navigateToHomePage = () => {
  window.location.href = 'https://echobox.com';
};

const Root = () => {
  const statusPageNotification = useLoaderData() as string | null;
  const revalidator = useRevalidator();

  useEffect(() => {
    const interval = setInterval(() => {
      if (revalidator.state === 'idle') {
        revalidator.revalidate();
      }
    }, STATUS_PAGE_REFRESH_INTERVAL);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex
      minH="100vh"
      direction="column"
      alignItems="center"
      backgroundImage={background}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
    >
      <Box textAlign="center" mt={[10, 20]} mb={10}>
        <Image
          htmlWidth="84px"
          src={logo}
          data-tip
          data-for="echobox-version"
          alt=""
          onClick={navigateToHomePage}
          cursor="pointer"
        />
      </Box>
      <Flex grow="1" direction="column" w={['100%', '440px']} px={[5, 0]}>
        <Box mb="3">
          {statusPageNotification && (
            <StatusPageNotification message={statusPageNotification} />
          )}
        </Box>
        <Card
          px={[5, 12]}
          py={[8, 12]}
          height="fit-content"
          flexDir="column"
          alignItems="flex-start"
        >
          <Outlet />
        </Card>
      </Flex>
      <Box mt={4}>
        <Footer />
      </Box>
    </Flex>
  );
};

export default Root;
