/* eslint-disable no-underscore-dangle */
import { format } from 'date-fns';

import { HOST_TYPES } from 'constants/constants';
import * as environment from 'utils/environment';
import * as errors from 'utils/errors';
import * as object from 'utils/object';
import { LoggerArgs } from '../types/types';

let version = '';
try {
  version = sessionStorage?.getItem('currentVersion') ?? '';
} catch (e) {
  version = '0.0.0';
  console.log(e);
}
const _LTracker = (window as any)._LTracker || [];

function logger(rawArgs: LoggerArgs) {
  const args = {
    log: rawArgs.log ?? true,
    event: rawArgs.event,
    properties: rawArgs.properties ?? {},
    severity: rawArgs.severity ?? 'info',
  };

  // Set dev mode to true if we are running locally
  // The catch block handles the case where we are running in a Jest environment
  let dev;
  try {
    dev = environment.getHostType() === HOST_TYPES.DEV;
  } catch (e) {
    dev = true;
  }

  // Add error details
  let message;
  let stack;
  if (rawArgs.error) {
    // Add error details if an error object has been passed
    if (rawArgs.error instanceof Error) {
      args.properties.error = JSON.parse(errors.stringifyError(rawArgs.error));
      const errorMessage = errors.getErrorMessage(rawArgs.error) as string;
      if (errorMessage) {
        args.properties.errorMessage = errorMessage;
      }
      const errorStatus = rawArgs.error?.status ?? rawArgs.error?.code;
      if (errorStatus) {
        args.properties.errorStatus = errorStatus;
      }
      message = rawArgs.error.message;
      stack = rawArgs.error.stack;
    }

    // Add error message and stacktrace if an errorEvent object has been passed
    if (rawArgs.error instanceof ErrorEvent) {
      if (rawArgs.error.error) {
        message = rawArgs.error.error.message;
        stack = rawArgs.error.error.stack;
      }
    }

    if (message) {
      args.properties.errorMessage = message;
    }
    if (stack) {
      args.properties.stack = stack;
    }

    // Add error details for non-"real" errors
    if (
      !(rawArgs.error instanceof Error) &&
      !(rawArgs.error instanceof ErrorEvent)
    ) {
      args.properties.error = rawArgs.error;
    }

    // Make error type a top-level property and remove it from the error details
    if (args.properties?.error?.type) {
      args.properties.errorType = args.properties.error.type;
      delete args.properties.error.type;
    }

    // Strip credentials from the error details
    args.properties.error = object.stripKeys(args.properties.error);
  }

  // Work out if we need to write to logging and/or tracking systems
  const sendToLogger =
    args.log &&
    typeof _LTracker !== 'undefined' &&
    !dev &&
    (args.severity === 'error' || args.severity === 'track');

  // Add version number to reported data
  args.properties.HV = version;

  // Add user agent to reported data
  if (window) {
    args.properties.UserAgent = window.navigator.userAgent;
  }

  // Stringify any non-essential elements to avoid creating unnecessary indexed fields in Loggly
  args.properties = errors.stringifyReport(args.properties);

  // Send event details to logging system
  if (sendToLogger) {
    try {
      const EventType = args.event;
      _LTracker.push({
        EventSource: 'Login-UI',
        EventType,
        ...args.properties,
      });
    } catch (e) {
      console.log(e);
    }
  }

  // Write event details to console when running locally
  if (
    args.log &&
    (dev || typeof _LTracker === 'undefined') &&
    process.env.NODE_ENV !== 'test'
  ) {
    if (args.severity === 'log') {
      console.log(args.event);
    } else if (args.severity === 'info' || args.severity === 'track') {
      console.log(`*** ${format(new Date(), 'HH:mm:ss.SSS')} ${args.event}`);
    } else {
      console.error(`Event: ${args.event}`);
      console.error(`Properties: ${JSON.stringify(args.properties, null, 2)}`);
    }
  }
}

function error(args: LoggerArgs) {
  logger({ ...args, severity: 'error' });
}

function info(args: LoggerArgs | string) {
  if (typeof args === 'undefined' || !args) {
    // Ignore request
  } else if (typeof args === 'string') {
    logger({ event: args, severity: 'info' });
  } else {
    logger({ ...args, severity: 'info' });
  }
}
function log(args: LoggerArgs | string) {
  if (typeof args === 'undefined' || !args) {
    // do nothing
  } else if (typeof args === 'string') {
    logger({ event: args, severity: 'log' });
  } else {
    logger({ ...args, severity: 'log' });
  }
}

function track(args: LoggerArgs | string) {
  if (typeof args === 'undefined' || !args) {
    // do nothing
  } else if (typeof args === 'string') {
    logger({ event: args, severity: 'track' });
  } else {
    logger({ ...args, severity: 'track' });
  }
}

export { error, info, log, track };
