import { useEffect, useState } from 'react';

import { useAuth } from 'context/authContext';
import { COGNITO_CHALLENGES } from 'constants/amplify';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'constants/constants';
import {
  Box,
  Button,
  Card,
  FormControl,
  Input,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import PasswordRules from 'components/PasswordRules';
import ErrorMessage from 'components/ErrorMessage';
import { Auth } from 'aws-amplify';
import { handleCognitoError } from 'utils/errors';
import { ICognitoError } from 'types/types';
import * as logger from 'utils/logger';

const NewPassword = () => {
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [resetError, setResetError] = useState('');
  const { user } = useAuth();
  const navigate = useNavigate();

  const containsUpperCase = /(?=.*[A-Z])/.test(password);
  const containsLowerCase = /(?=.*[a-z])/.test(password);
  const containsNumberCharacter = /^(?=.*[0-9])/.test(password);
  const containsTenCharacters = password.length >= 10;

  const canSave =
    containsUpperCase &&
    containsLowerCase &&
    containsNumberCharacter &&
    containsTenCharacters;

  const handleNewPassword = async () => {
    setResetError('');
    try {
      const { requiredAttributes } = user.challengeParam;
      await Auth.completeNewPassword(user, password, requiredAttributes);
      await Auth.signOut({ global: true });
      navigate(ROUTES.HOME);
    } catch (error: any) {
      if (error instanceof Error) {
        const { message } = handleCognitoError(error as ICognitoError);
        setResetError(message);
      }
      logger.error({ event: 'NEW_PASSWORD_ERROR', error });
    }
  };

  useEffect(() => {
    if (
      !user?.challengeName ||
      user.challengeName !== COGNITO_CHALLENGES.NEW_PASSWORD_REQUIRED
    ) {
      navigate(ROUTES.LOGIN);
    }
  }, [navigate, user]);

  return (
    <>
      <Card.Title variant="h3" pb="6">
        Set your new password
      </Card.Title>
      <Card.Content w="full">
        <Box>
          <FormControl>
            <FormControl.FormLabel mr="0">
              New password
              <Button
                variant="link"
                float="right"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? 'Hide' : 'Show'} password
              </Button>
            </FormControl.FormLabel>
            <Input
              size="lg"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={e => setPassword(e.target.value)}
              maxLength={128}
              autoFocus
            />
          </FormControl>
          <PasswordRules
            containsUpperCase={containsUpperCase}
            containsLowerCase={containsLowerCase}
            containsNumberCharacter={containsNumberCharacter}
            containsTenCharacters={containsTenCharacters}
          />
        </Box>
        <Button
          w="full"
          size="lg"
          mt="6"
          isDisabled={!canSave}
          onClick={handleNewPassword}
        >
          Set new password
        </Button>
        {resetError && <ErrorMessage>{resetError}</ErrorMessage>}
      </Card.Content>
    </>
  );
};

export default NewPassword;
