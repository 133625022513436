import {
  Box,
  Flex,
  FormControl,
  Heading,
  Input,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { Auth } from 'aws-amplify';
import PasswordRules from 'components/PasswordRules';

import { ROUTES } from 'constants/constants';
import { useAuth } from 'context/authContext';
import {
  resetSettingsState,
  setSettingsChanged,
  setSettingsError,
  setSettingsSaving,
  updateOnSave,
  useSettingsContext,
} from 'context/settingsContext';
import useNotification from 'hooks/useNotification';
import SettingsWrapper from 'layouts/Settings';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ICognitoError } from 'types/types';
import { handleCognitoError } from 'utils/errors';
import * as logger from 'utils/logger';
import * as tracker from 'utils/tracker';

const Password = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState('');
  const [isPasswordDifferent, setIsPasswordDifferent] = useState(false);
  const [isIncorrectCurrentPassword, setIsIncorrectCurrentPassword] =
    useState(false);
  const { dispatch } = useSettingsContext();
  const { isStaffUser } = useAuth();
  const navigate = useNavigate();
  const { successNotification, errorNotification } = useNotification();

  const containsUpperCase = /(?=.*[A-Z])/.test(newPassword);
  const containsLowerCase = /(?=.*[a-z])/.test(newPassword);
  const containsNumberCharacter = /^(?=.*[0-9])/.test(newPassword);
  const containsTenCharacters = newPassword.length >= 10;

  const canSave =
    containsUpperCase &&
    containsLowerCase &&
    containsNumberCharacter &&
    containsTenCharacters;

  const handleSave = useCallback(async () => {
    if (newPassword !== verifyPassword) {
      setIsPasswordDifferent(true);
      setSettingsError(dispatch);
    } else {
      setSettingsSaving(dispatch);
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(currentUser, currentPassword, newPassword);
        resetSettingsState(dispatch);
        successNotification(
          'Password updated',
          'Your password has been updated successfully'
        );
        tracker.track({
          eventName: 'Change Password',
        });
        resetSettingsState(dispatch);
      } catch (error: any) {
        if (error instanceof Error) {
          const { code, message } = handleCognitoError(error as ICognitoError);
          if (code === 'NotAuthorizedException') {
            setIsIncorrectCurrentPassword(true);
            setSettingsError(dispatch);
          } else {
            errorNotification(message);
            setSettingsChanged(dispatch);
          }
        }
        logger.error({ event: 'UPDATE_USER_PASSWORD', error });
      }
    }
  }, [
    currentPassword,
    dispatch,
    errorNotification,
    newPassword,
    successNotification,
    verifyPassword,
  ]);

  const handleVerifyPassword = (value: string) => {
    setVerifyPassword(value);
    setIsPasswordDifferent(false);
  };

  const handleCurrentPassword = (value: string) => {
    setCurrentPassword(value);
    setIsIncorrectCurrentPassword(false);
  };

  useEffect(() => {
    if (isStaffUser) {
      navigate(`/${ROUTES.MY_PROFILE}`);
    }
  }, [isStaffUser, navigate]);

  useEffect(() => {
    if (currentPassword) {
      if (canSave && verifyPassword) {
        setSettingsChanged(dispatch);
        updateOnSave(dispatch, handleSave);
      } else setSettingsError(dispatch);
    } else {
      resetSettingsState(dispatch);
    }
  }, [
    canSave,
    currentPassword,
    dispatch,
    handleSave,
    newPassword,
    verifyPassword,
  ]);

  // reset settings state on unmount
  useEffect(() => {
    return () => resetSettingsState(dispatch);
  }, [dispatch]);

  return (
    <SettingsWrapper
      routes={[
        { label: 'Profile', link: `/${ROUTES.MY_PROFILE}` },
        {
          label: 'Password',
          link: `/${ROUTES.PASSWORD}`,
        },
      ]}
    >
      <Flex flexDir="column" gap={6} align="flex-start">
        <Box>
          <Heading variant="h2">Password</Heading>
          <Text size="sm" color="gray.600" mt={2}>
            Update your password
          </Text>
        </Box>
        <Flex flexDir="column" gap={5} minW="420px">
          <FormControl isInvalid={isIncorrectCurrentPassword}>
            <FormControl.FormLabel mr="0">
              Current password
            </FormControl.FormLabel>
            <Input
              type="password"
              value={currentPassword}
              onChange={e => handleCurrentPassword(e.target.value)}
              maxLength={128}
            />
            {isIncorrectCurrentPassword && (
              <FormControl.FormErrorMessage>
                Your current password is incorrect
              </FormControl.FormErrorMessage>
            )}
          </FormControl>
          <Box>
            <FormControl>
              <FormControl.FormLabel>New password</FormControl.FormLabel>
              <Input
                type="password"
                value={newPassword}
                onChange={e => setNewPassword(e.target.value)}
                maxLength={128}
              />
            </FormControl>
            <PasswordRules
              containsUpperCase={containsUpperCase}
              containsLowerCase={containsLowerCase}
              containsNumberCharacter={containsNumberCharacter}
              containsTenCharacters={containsTenCharacters}
              paddingLeft={0}
            />
          </Box>
          <FormControl isInvalid={isPasswordDifferent}>
            <FormControl.FormLabel>Verify new password</FormControl.FormLabel>
            <Input
              type="password"
              value={verifyPassword}
              onChange={e => handleVerifyPassword(e.target.value)}
              maxLength={128}
            />
            {isPasswordDifferent && (
              <FormControl.FormErrorMessage>
                Passwords do not match
              </FormControl.FormErrorMessage>
            )}
          </FormControl>
        </Flex>
      </Flex>
    </SettingsWrapper>
  );
};

export default Password;
