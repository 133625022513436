import {
  Box,
  Button,
  Card,
  Divider,
  Flex,
  FormControl,
  Input,
  Link,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { Auth } from 'aws-amplify';
import ErrorMessage from 'components/ErrorMessage';
import { ROUTES } from 'constants/constants';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ICognitoError } from 'types/types';
import { handleCognitoError } from 'utils/errors';
import * as logger from 'utils/logger';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [isEmailError, setIsEmailError] = useState(false);
  const [forgotPasswordError, setForgotPasswordError] = useState('');

  const navigate = useNavigate();

  const resetErrors = () => {
    setIsEmailError(false);
    setForgotPasswordError('');
  };

  const handleResetPassword = async () => {
    resetErrors();
    if (!email) {
      setIsEmailError(true);
      return;
    }
    if (email.match(/@echobox(app)?.com$/)) {
      setForgotPasswordError('Staff users cannot reset their passwords.');
      return;
    }

    try {
      const trimmedEmail = email.trim().toLowerCase();
      await Auth.forgotPassword(trimmedEmail);
      sessionStorage.setItem('email', trimmedEmail);
      navigate(`/${ROUTES.RESET_PASSWORD}`);
    } catch (error: any) {
      if (error instanceof Error) {
        const { message } = handleCognitoError(error as ICognitoError);
        setForgotPasswordError(message);
      }
      logger.error({ event: 'FORGOT_PASSWORD_ERROR', error });
    }
  };

  return (
    <>
      <Card.Title variant="h3" pb="6">
        Recover your account
      </Card.Title>
      <Card.Content w="full">
        <Flex direction="column" gap="4">
          <Text size="sm">
            Please enter your email address and we’ll send you the instructions
            to reset your password.
          </Text>
          <FormControl isInvalid={isEmailError}>
            <FormControl.FormLabel>Email</FormControl.FormLabel>
            <Input
              size="lg"
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              maxLength={128}
              autoFocus
            />
            {isEmailError && (
              <FormControl.FormErrorMessage>
                Enter your email
              </FormControl.FormErrorMessage>
            )}
          </FormControl>
        </Flex>
        <Box my="6">
          <Button
            w="full"
            size="lg"
            onClick={handleResetPassword}
            loadingText={<span>Resetting your password</span>}
            {...(forgotPasswordError && { mb: '2' })}
          >
            <span>Reset your password</span>
          </Button>
          {forgotPasswordError && (
            <ErrorMessage>{forgotPasswordError}</ErrorMessage>
          )}
        </Box>
        <Flex alignItems="center" gap="2" mb="5">
          <Divider color="gray.400" />
          <Text size="xs" color="gray.600" whiteSpace="nowrap">
            Need more help?
          </Text>
          <Divider color="gray.400" />
        </Flex>
        <Text size="sm">
          If you feel like you need more help, feel free to reach out,
          we&rsquo;re here to help.&nbsp;
          <Link href="mailto:support@echobox.com?subject=Account recovery help">
            support@echobox.com
          </Link>
        </Text>
      </Card.Content>
    </>
  );
};
export default ForgotPassword;
