import {
  Heading,
  HStack,
  Link,
  Tooltip,
} from '@ebx-ui/ebx-ui-component-library-sdk';

const Footer = () => {
  const currentVersion = sessionStorage.getItem('currentVersion');
  return (
    <HStack spacing="6" margin="8">
      <Link href="mailto:support@echobox.com">Contact support</Link>
      <Tooltip label={`Echobox Login version ${currentVersion}`}>
        <Heading variant="h5">&copy; Echobox Ltd</Heading>
      </Tooltip>
      <Link href="https://www.echobox.com/">Back to homepage</Link>
    </HStack>
  );
};

export default Footer;
