import { HOST_TYPES } from 'constants/constants';

export const sentry = {
  [HOST_TYPES.PROD]: {
    dsn: 'https://1193348182cb4415bf0704c409b252b6@o155242.ingest.sentry.io/4504157096837120',
    tracesSampleRate: 0.01,
  },
  [HOST_TYPES.STAGING]: {
    dsn: 'https://f1b49bfe3ded45dd8ca255bd4a518c6a@o155242.ingest.sentry.io/4504157095329792',
    tracesSampleRate: 0.01,
  },
};
