import mixpanel from 'mixpanel-browser';
import { Auth } from 'aws-amplify';

import { FixTypeLater, TrackerArgs } from 'types/types';
import { LAST_USED_PRODUCT_KEY } from 'constants/constants';

import * as environment from './environment';
import * as logger from './logger';
import * as cookie from './cookie';

const hashEmailAddress = async (email: string) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(email.toLowerCase());
  const hash = await crypto.subtle.digest('SHA-1', data);
  const array = Array.from(new Uint8Array(hash));
  const hex = array.map(byte => byte.toString(16).padStart(2, '0')).join('');
  return hex;
};

export const setProfile = async (user: FixTypeLater) => {
  try {
    if (user) {
      const mixpanelId = await hashEmailAddress(user?.attributes?.email);
      const userProfile = {
        $distinct_id: mixpanelId,
        $name: user?.attributes?.name,
        $email: user?.attributes?.email,
        HV: window.sessionStorage.getItem('currentVersion'),
      };
      if (!environment.isDev()) {
        mixpanel.people.set(userProfile);
        mixpanel.identify(mixpanelId);
      } else {
        logger.info(`mixpanel setProfile: ${JSON.stringify(userProfile)}`);
      }
    }
  } catch (error: any) {
    logger.info(`mixpanel setProfile error: ${error}`);
  }
};

export const track = async ({ eventName, trackingParams }: TrackerArgs) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    if (user) {
      const mixpanelId = await hashEmailAddress(user?.attributes?.email);
      const origin =
        document.referrer || cookie.getCookieValue(LAST_USED_PRODUCT_KEY);

      const properties = {
        $distinct_id: mixpanelId,
        $email: user?.attributes?.email,
        $name: user?.attributes?.name,
        HV: window.sessionStorage.getItem('currentVersion'),
        origin,
        ...trackingParams,
      };
      if (!environment.isDev()) {
        mixpanel.track(eventName, properties);
      } else {
        logger.info(
          `mixpanel track: ${eventName} ${JSON.stringify(properties)}`
        );
      }
    }
  } catch (error: any) {
    logger.info(`mixpanel track error: ${eventName} ${error?.message}`);
  }
};
