import { useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Flex,
  HStack,
  PinInput,
  PinInputField,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import ErrorMessage from 'components/ErrorMessage';
import { useAuth } from 'context/authContext';
import { handleCognitoError } from 'utils/errors';
import { ICognitoError } from 'types/types';
import { COGNITO_CHALLENGES } from 'constants/amplify';
import { ROUTES } from 'constants/constants';
import * as logger from 'utils/logger';

const MFA = () => {
  const [code, setCode] = useState('');
  const [mfaError, setMfaError] = useState('');
  const { user, setUser } = useAuth();
  const verify = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();

  const handleVerify = async () => {
    setMfaError('');
    try {
      const response = await Auth.confirmSignIn(user, code, user.challengeName);
      const newUser = { ...response };
      setUser(newUser);
    } catch (error: any) {
      if (error instanceof Error) {
        const { message } = handleCognitoError(error as ICognitoError);
        setMfaError(message);
      }
      logger.error({ event: 'VERIFY_MFA_CODE_ERROR', error });
    }
  };

  useEffect(() => {
    if (code.length === 6 && verify?.current) verify.current.click();
  }, [code]);

  useEffect(() => {
    if (
      !user?.challengeName ||
      user.challengeName !== COGNITO_CHALLENGES.SOFTWARE_TOKEN_MFA
    ) {
      navigate(`/${ROUTES.LOGIN}`);
    }
  }, [navigate, user]);

  const isInvalid = !!mfaError;

  return (
    <>
      <Card.Title variant="h3" pb="6">
        Two-Factor Authentication
      </Card.Title>
      <Card.Content w="full" gap="0">
        <Flex direction="column" gap="6">
          <Text size="sm">
            Open the authentication app on your device to view your
            authentication code and verify your identity
          </Text>
          <HStack justifyContent="space-between" borderColor="gray.300">
            <PinInput
              onChange={value => setCode(value)}
              placeholder=""
              errorBorderColor="red.600"
              focusBorderColor="primary.600"
              isInvalid={isInvalid}
              autoFocus
            >
              {[...Array(6)].map(index => (
                <PinInputField
                  key={index}
                  {...(isInvalid && { color: 'red.600' })}
                />
              ))}
            </PinInput>
          </HStack>
        </Flex>
        <Button
          ref={verify}
          w="full"
          size="lg"
          mt="6"
          isDisabled={code.length !== 6}
          onClick={handleVerify}
          loadingText={<span>Verifying</span>}
          {...(mfaError && { mb: '2' })}
        >
          <span>Verify</span>
        </Button>
        {mfaError && <ErrorMessage>{mfaError}</ErrorMessage>}
      </Card.Content>
    </>
  );
};

export default MFA;
