import { LOGGLY_SETTINGS } from 'constants/loggly';
import { FixTypeLater } from 'types/types';

function stripKeys(object: FixTypeLater) {
  if (!object || typeof object !== 'object') {
    return object;
  }
  const result: FixTypeLater = {};
  Object.keys(object).forEach(key => {
    if (typeof object[key] !== 'object') {
      if (
        LOGGLY_SETTINGS.KEYS_TO_EXCLUDE.indexOf(key) === -1 &&
        (LOGGLY_SETTINGS.EXCLUDE_IF_EMPTY.indexOf(key) === -1 ||
          object[key] !== '')
      ) {
        result[key] = object[key];
      }
    } else {
      result[key] = stripKeys(object[key]);
    }
  });
  return result;
}

export { stripKeys };
