import {
  Breadcrumb,
  Flex,
  SettingsNavbar,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { ROUTES } from 'constants/constants';
import { useSettingsContext } from 'context/settingsContext';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { isValidRedirect, navigateTo } from 'utils/url';

interface SettingsProps {
  routes: { link: string; label: string }[];
  children: React.ReactNode;
}

const RETURN_PATH_KEY = 'returnPath';

const SettingsWrapper = ({ routes, children }: SettingsProps) => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { actionButtonStatus, actionButtonSaveText, onSave } =
    useSettingsContext();

  useEffect(() => {
    if (searchParams?.has(RETURN_PATH_KEY)) {
      sessionStorage.setItem(
        RETURN_PATH_KEY,
        searchParams.get(RETURN_PATH_KEY) ?? ''
      );
      // Remove query parameter from URL
      navigate(pathname, { replace: true });
    }
  }, [navigate, pathname, searchParams]);

  const handleRedirect = () => {
    const returnPath = sessionStorage.getItem(RETURN_PATH_KEY);
    sessionStorage.removeItem(RETURN_PATH_KEY);
    const referrer = document.referrer;
    let redirect = ROUTES.HOME;
    if (isValidRedirect(referrer)) {
      redirect = returnPath ? `${referrer}${returnPath}` : referrer;
    }
    navigateTo(redirect);
  };

  return (
    <>
      <Helmet>
        <title>Echobox Profile</title>
      </Helmet>
      <Flex h="100vh" position="relative">
        <SettingsNavbar
          actionButtonStatus={actionButtonStatus}
          actionButtonSaveText={actionButtonSaveText}
          onSave={onSave}
          onClose={handleRedirect}
        >
          <Breadcrumb size="lg">
            {routes.map(route => (
              <Breadcrumb.Item key={route.link}>
                <Breadcrumb.Link
                  as={Link}
                  to={route.link}
                  isCurrentPage={pathname === route.link}
                >
                  {route.label}
                </Breadcrumb.Link>
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </SettingsNavbar>
        <Flex
          width="full"
          mt={15}
          backgroundColor="gray.100"
          px={10}
          py={12}
          flexGrow={1}
          h="calc(100% - 60px)"
        >
          {children}
        </Flex>
      </Flex>
    </>
  );
};

export default SettingsWrapper;
