import { Heading } from '@ebx-ui/ebx-ui-component-library-sdk';

import { ChildrenType } from 'types/types';

const ErrorMessage = ({ children }: ChildrenType) => (
  <Heading color="red.600" variant="h5">
    {children}
  </Heading>
);

export default ErrorMessage;
