import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import NavigateToApp from 'NavigateToApp';
import { ROUTES } from 'constants/constants';
import MyProfile from 'screens/MyProfile';
import PersonalDetails from 'screens/PersonalDetails';
import { SettingsProvider } from 'context/settingsContext';
import Password from 'screens/Password';
import { useAuth } from 'context/authContext';

const AuthenticatedApp = () => {
  const { isStaffUser } = useAuth();

  const router = createBrowserRouter([
    {
      path: '*',
      element: <NavigateToApp />,
    },
    {
      path: ROUTES.MY_PROFILE,
      element: (
        <SettingsProvider>
          <MyProfile />
        </SettingsProvider>
      ),
    },
    {
      path: ROUTES.PERSONAL_DETAILS,
      element: (
        <SettingsProvider>
          <PersonalDetails />
        </SettingsProvider>
      ),
    },
    ...(isStaffUser
      ? []
      : [
          {
            path: ROUTES.PASSWORD,
            element: (
              <SettingsProvider>
                <Password />
              </SettingsProvider>
            ),
          },
        ]),
  ]);
  return <RouterProvider router={router} />;
};

export default AuthenticatedApp;
