import { useEffect } from 'react';

import { Auth } from 'aws-amplify';
import {
  AUTH_MESSAGE_TYPES,
  HOST_TYPES,
  WHITELISTED_DOMAINS,
} from 'constants/constants';
import { FixTypeLater } from 'types/types';

import * as environment from 'utils/environment';
import * as logger from 'utils/logger';

const checkIfValidOrigin = (origin: string) => {
  const hostType = environment.getHostType();
  const originHostType = environment.getHostType(origin);
  const originURL = new URL(origin);
  const whitelistedDomains = WHITELISTED_DOMAINS[hostType];
  // check if protocol is https when host type is not dev
  if (hostType !== HOST_TYPES.DEV && originURL.protocol !== 'https:') {
    return false;
  }

  // for staging, host type should be same
  if (hostType === HOST_TYPES.STAGING && hostType === originHostType) {
    return true;
  }

  // otherwise, check if hostname is in whitelisted domains list for the current environment
  if (whitelistedDomains.includes(originURL.hostname)) {
    return true;
  }

  return false;
};

const generateTokens = (session: FixTypeLater) => {
  const keyPrefix = `${session.keyPrefix}.${session.username}`;
  const tokens = {
    [`${keyPrefix}.clockDrift`]: session.signInUserSession.clockDrift,
    [`${keyPrefix}.idToken`]: session.signInUserSession.idToken.jwtToken,
    [`${keyPrefix}.accessToken`]:
      session.signInUserSession.accessToken.jwtToken,
    [`${keyPrefix}.refreshToken`]: session.signInUserSession.refreshToken.token,
    [`${session.keyPrefix}.LastAuthUser`]: session.username,
    [session.userDataKey]: {
      UserAttributes: session.attributes,
      Username: session.username,
    },
  };
  return tokens;
};

const handleGetTokens = async (event: MessageEvent) => {
  try {
    logger.track({
      event: 'GET_TOKENS_REQUEST',
    });
    const session = await Auth.currentAuthenticatedUser();
    const cognitoTokens = generateTokens(session);
    if (event.source) {
      event.source.postMessage(
        { tokens: cognitoTokens, status: 200 },
        { targetOrigin: event.origin }
      );
    }
  } catch (error: any) {
    logger.error({
      event: 'GET_TOKENS_ERROR',
      error,
    });
    if (event.source) {
      event.source.postMessage({ status: 401 }, { targetOrigin: event.origin });
    }
  }
};

const handleSignOut = async (event: MessageEvent) => {
  try {
    await Auth.signOut();
    if (event.source) {
      event.source.postMessage({ status: 200 }, { targetOrigin: event.origin });
    }
  } catch (error) {
    logger.error({
      event: 'SIGN_OUT_ERROR',
      properties: {
        other: {
          eventOrigin: event.origin,
          eventData: event.data,
        },
      },
    });
  }
};

const MessageHandler = () => {
  useEffect(() => {
    window.onmessage = async (event: MessageEvent) => {
      const isValidOrigin = checkIfValidOrigin(event?.origin);

      if (isValidOrigin) {
        switch (event.data) {
          case AUTH_MESSAGE_TYPES.GET_TOKENS:
            await handleGetTokens(event);
            break;
          case AUTH_MESSAGE_TYPES.SIGN_OUT:
            await handleSignOut(event);
            break;
          case 'TEST_MESSAGE_REQUEST':
            if (event.source) {
              event.source.postMessage('TEST_MESSAGE_RESPONSE', {
                targetOrigin: event.origin,
              });
            }
            break;
          default:
            break;
        }
      } else {
        logger.error({
          event: 'INVALID_ORIGIN',
          properties: {
            other: {
              eventOrigin: event?.origin,
              eventData: event?.data,
            },
          },
        });
      }
    };
  }, []);

  return null;
};

export default MessageHandler;
