import { Button, Modal, Text } from '@ebx-ui/ebx-ui-component-library-sdk';
import { Auth } from 'aws-amplify';

import { PREFERRED_MFA_TYPES } from 'constants/constants';
import { useAuth } from 'context/authContext';
import useNotification from 'hooks/useNotification';
import { ICognitoError } from 'types/types';
import { handleCognitoError } from 'utils/errors';

import * as logger from 'utils/logger';
import * as tracker from 'utils/tracker';

const TurnOffMFAModal = ({ onClose }: { onClose: () => void }) => {
  const { refreshUser } = useAuth();
  const { successNotification, errorNotification } = useNotification();

  const handleTurnOff = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const response = await Auth.setPreferredMFA(
        user,
        PREFERRED_MFA_TYPES.NOMFA
      );
      if (response === 'SUCCESS') {
        tracker.track({
          eventName: 'Turn off MFA',
        });
        successNotification(
          'Two-Factor Authentication turned off successfully'
        );
        refreshUser();
        onClose();
      }
    } catch (error: any) {
      if (error instanceof Error) {
        const { message } = handleCognitoError(error as ICognitoError);
        errorNotification(message);
      }
      logger.error({ event: 'TURN_OFF_MFA', error });
    }
  };

  return (
    <Modal isOpen onClose={onClose}>
      <Modal.Header>
        <Modal.Title>Turn off Two-Factor Authentication?</Modal.Title>
      </Modal.Header>
      <Modal.Body color="gray.900">
        <Text>
          If you turn off Two-Factor Authentication, your account will only be
          protected by your password.
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleTurnOff}>Turn Off</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TurnOffMFAModal;
