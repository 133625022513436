import {
  List,
  ListIcon as BaseListIcon,
  ListItem,
  ListProps,
  SignCheckIcon,
  SignUncheckIcon,
  Text as BaseText,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { ChildrenType } from 'types/types';

const Text = ({ children }: ChildrenType) => (
  <BaseText size="sm" display="inline-block">
    {children}
  </BaseText>
);

const ListIcon = ({ check }: { check: boolean }) => (
  <BaseListIcon
    as={check ? SignCheckIcon : SignUncheckIcon}
    mr="1"
    mt="3px"
    color={check ? 'green.600' : 'gray.600'}
  />
);

interface IPasswordRules extends ListProps {
  containsUpperCase: boolean;
  containsLowerCase: boolean;
  containsNumberCharacter: boolean;
  containsTenCharacters: boolean;
}

const PasswordRules = ({
  containsUpperCase,
  containsLowerCase,
  containsNumberCharacter,
  containsTenCharacters,
  ...rest
}: IPasswordRules) => (
  <List spacing="2" pt="3" pl="6" {...rest}>
    <ListItem display="flex">
      <ListIcon check={containsTenCharacters} />
      <Text>At least 10 characters long</Text>
    </ListItem>
    <ListItem display="flex">
      <ListIcon check={containsLowerCase} />
      <Text>One lowercase character</Text>
    </ListItem>
    <ListItem display="flex">
      <ListIcon check={containsUpperCase} />
      <Text>One uppercase character</Text>
    </ListItem>
    <ListItem display="flex">
      <ListIcon check={containsNumberCharacter} />
      <Text>One number character</Text>
    </ListItem>
  </List>
);

export default PasswordRules;
