import { Alert } from '@ebx-ui/ebx-ui-component-library-sdk';
import * as Sentry from '@sentry/react';
import * as React from 'react';

import * as logger from 'utils/logger';

const DefaultFallback = () => (
  <Alert variant="error">
    <Alert.Title>An unexpected error occurred</Alert.Title>
  </Alert>
);

interface ErrorBoundaryProps {
  children: React.ReactNode;
  fallback?: React.ComponentPropsWithoutRef<
    typeof Sentry.ErrorBoundary
  >['fallback'];
  logglyProperties?: Record<string, unknown>;
  showMessageOnError?: boolean;
}

const ErrorBoundary = ({
  children,
  fallback,
  logglyProperties = {},
  showMessageOnError = true,
}: ErrorBoundaryProps) => {
  const version = sessionStorage.getItem('currentVersion') ?? '0.0.0';

  const beforeCapture = (scope: Sentry.Scope) => {
    scope.setTag('version', version);
  };

  const onError = (error: Error) => {
    logger.error({
      event: 'Unhandled Error caught by ErrorBoundary',
      properties: {
        error: { type: 'LOGIN_APP', error },
        ...logglyProperties,
      },
    });
  };

  return (
    <Sentry.ErrorBoundary
      beforeCapture={beforeCapture}
      onError={onError}
      {...(showMessageOnError && { fallback: fallback ?? <DefaultFallback /> })}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
