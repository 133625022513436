import {
  Button,
  ButtonGroup,
  HStack,
  Modal,
  PinInput,
  PinInputField,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { Auth } from 'aws-amplify';
import useNotification from 'hooks/useNotification';
import { useState } from 'react';

import * as logger from 'utils/logger';

const VerifyEmailModal = ({
  emailAddress,
  onClose,
  onSuccess,
  onError,
}: {
  emailAddress: string;
  onClose: () => void;
  onSuccess: () => void;
  onError: (message: string) => void;
}) => {
  const [code, setCode] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const { successNotification } = useNotification();

  const handleChange = (value: string) => {
    setCode(value);
    setIsInvalid(false);
  };

  const handleVerify = async () => {
    try {
      await Auth.verifyCurrentUserAttributeSubmit('email', code);
      onSuccess();
    } catch (error: any) {
      console.error('Error verifying email', error);
      logger.error({
        event: 'VERIFY_EMAIL_ERROR',
        error,
      });
      onError(error?.message || "Sorry, we couldn't verify the code");
      setIsInvalid(true);
    }
  };

  const handleResend = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(currentUser, {
        email: emailAddress,
      });
      successNotification('A new code has been sent');
    } catch (error: any) {
      console.error('Error resending code', error);
      logger.error({
        event: 'VERIFY_EMAIL_RESEND_ERROR',
        error,
      });
      onError(error?.message || "Sorry, we couldn't resend the code");
    }
  };

  return (
    <Modal isOpen onClose={onClose}>
      <Modal.Header>
        <Modal.Title>Verify your email address</Modal.Title>
      </Modal.Header>
      <Modal.Body color="gray.900">
        <Text mb="5">
          We have sent an email to <strong>{emailAddress}</strong> with a
          verification code. Please enter it below to change your email address.
        </Text>
        <HStack justifyContent="center" gap="2" borderColor="gray.300">
          <PinInput
            onChange={value => handleChange(value)}
            placeholder=""
            errorBorderColor="red.600"
            focusBorderColor="primary.600"
            isInvalid={isInvalid}
            autoFocus
          >
            {[...Array(6)].map(index => (
              <PinInputField
                key={index}
                {...(isInvalid && { color: 'red.600' })}
              />
            ))}
          </PinInput>
        </HStack>
      </Modal.Body>
      <Modal.Footer justifyContent="space-between">
        <Button variant="link" onClick={handleResend}>
          Resend code
        </Button>
        <ButtonGroup>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleVerify} isDisabled={code?.length < 6}>
            Verify
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default VerifyEmailModal;
