import AuthenticatedApp from 'AuthenticatedApp';
import { useAuth } from 'context/authContext';
import UnauthenticatedApp from 'UnauthenticatedApp';
import { ROUTES } from 'constants/constants';
import MessageHandler from 'screens/MessageHandler';

const App = () => {
  const { user } = useAuth();

  const isMessages = window.location.pathname === `/${ROUTES.MESSAGES}`;

  if (isMessages) {
    return <MessageHandler />;
  }
  return user?.signInUserSession ? (
    <AuthenticatedApp />
  ) : (
    <UnauthenticatedApp />
  );
};

export default App;
