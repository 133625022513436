import {
  API_TIMEOUTS,
  STATUS_PAGE_COMPONENT_ID,
  STATUS_PAGE_URL,
} from 'constants/constants';
import { client } from 'utils/api';
import { getUnixTimestamp } from 'utils/datetime';
import * as logger from 'utils/logger';

interface Response {
  incidents: {
    incident_updates: {
      affected_components: {
        code: string;
      }[];
      id: string;
      body: string;
      display_at: number;
    }[];
  }[];
}

export default async function getStatusPageNotification() {
  try {
    const { incidents } = await client<Response>(
      STATUS_PAGE_URL,
      'incidents/unresolved.json',
      { timeout: API_TIMEOUTS.S }
    );

    const update = incidents[0]?.incident_updates[0];
    if (
      update?.display_at &&
      getUnixTimestamp(new Date(update.display_at)) <= getUnixTimestamp() &&
      update.affected_components.some(c => c.code === STATUS_PAGE_COMPONENT_ID)
    ) {
      return update.body;
    }
    return null;
  } catch (error: any) {
    logger.info(`getStatusPageNotification: ${error?.message}`);
    return null;
  }
}
