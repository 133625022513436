export const API_TIMEOUTS = {
  XXS: 5000, // 5 seconds
  XS: 15000, // 15 seconds
  S: 60000, // 1 minute
  M: 120000, // 2 minutes
  LM: 180000, // 3 minutes
  L: 240000, // 4 minutes
  XL: 360000, // 6 minutes
  XXL: 720000, // 12 minutes
  XXXL: 1500000, // 25 minutes
};

export const HOST_TYPES = {
  DEV: 'dev',
  PROD: 'prod',
  STAGING: 'staging',
};

export const ONE_DAY_IN_SECONDS = 86400e3;
export const UNIX_EPOCH = 'Thu, 01 Jan 1970 00:00:00 GMT';

export const ROUTES = {
  HOME: '/',
  LOGIN: 'login',
  MFA: 'mfa',
  NEW_PASSWORD: 'new-password',
  RESET_PASSWORD: 'reset-password',
  FORGOT_PASSWORD: 'forgot-password',
  MESSAGES: 'messages',
  MY_PROFILE: 'myprofile',
  PERSONAL_DETAILS: 'myprofile/personal-details',
  PASSWORD: 'myprofile/password',
};

export const APPS = [
  {
    cognitoGroup: 'SOCIAL_FRONTEND_USER',
    urls: {
      [HOST_TYPES.PROD]: 'https://social.echobox.com',
      [HOST_TYPES.STAGING]: 'https://stage.sl.echobox.dev',
      [HOST_TYPES.DEV]: 'https://stage.sl.echobox.dev',
    },
  },
  {
    cognitoGroup: 'NEWSLETTER_FRONTEND_USER',
    urls: {
      [HOST_TYPES.PROD]: 'https://email.echobox.com',
      [HOST_TYPES.STAGING]: 'https://stage.nl.echobox.dev',
      [HOST_TYPES.DEV]: 'https://stage.nl.echobox.dev',
    },
  },
  {
    cognitoGroup: 'BENCHMARK_FRONTEND_USER',
    urls: {
      [HOST_TYPES.PROD]: 'https://benchmarks.echobox.com',
      [HOST_TYPES.STAGING]: 'https://stage.bm.echobox.dev',
      [HOST_TYPES.DEV]: 'https://stage.bm.echobox.dev',
    },
  },
  {
    cognitoGroup: 'BENCHMARK_FRONTEND_USER',
    urls: {
      [HOST_TYPES.PROD]: 'https://traffic.echobox.com',
      [HOST_TYPES.STAGING]: 'https://traffic.bm.echobox.dev',
      [HOST_TYPES.DEV]: 'https://traffic.bm.echobox.dev',
    },
  },
];

export const LAST_USED_PRODUCT_KEY = 'EbxLastUsedProduct';

export const COGNITO_ERRORS = {
  USER_NOT_FOUND: 'UserNotFoundException',
  NOT_AUTHORIZED: 'NotAuthorizedException',
  LIMITED_EXCEED: 'LimitExceededException',
  EXPIRED_CODE: 'ExpiredCodeException',
  INVALID_CODE_ENABLING_MFA: 'EnableSoftwareTokenMFAException',
  INVALID_CODE: 'CodeMismatchException',
  INVALID_SESSION: 'INVALID_SESSION',
  INTERNAL_SERVER_ERROR: 'InternalServerErrorException',
};

export const COGNITO_ERROR_MESSAGES = {
  [COGNITO_ERRORS.INVALID_CODE_ENABLING_MFA]: 'Invalid code. Please try again.',
  [COGNITO_ERRORS.USER_NOT_FOUND]:
    'User not found, please check the email address is entered correctly.',
  [COGNITO_ERRORS.INVALID_CODE]:
    'The password reset code is not valid. Please request a new code and try again.',
  [COGNITO_ERRORS.INTERNAL_SERVER_ERROR]:
    'Something went wrong. Please try again.',
  [COGNITO_ERRORS.NOT_AUTHORIZED]: 'Incorrect username or password.',
  [COGNITO_ERRORS.INVALID_SESSION]: 'No current user',
};

export const PREFERRED_MFA_TYPES = {
  TOTP: 'TOTP',
  NOMFA: 'NOMFA',
} as const;

export const API_ERROR_TYPES = {
  API_HTTP_COGNITO: 'API_HTTP_COGNITO',
  API_HTTP_EXTERNAL: 'API_HTTP_EXTERNAL',
};

export const MAX_LOGIN_ATTEMPTS_WITHOUT_HELP = 3;

export const STATUS_PAGE_URL = 'https://jbt229h9v3tn.statuspage.io/api/v2';
export const STATUS_PAGE_COMPONENT_ID = 'kh1ppyhhktnj';

export const STATUS_PAGE_REFRESH_INTERVAL = 60000; // one minute

export const NO_PERMISSIONS_PREFIX = 'NoPermissions_';

export const LOGIN_STARTED = 'Login_Started';

export const WHITELISTED_DOMAINS = {
  [HOST_TYPES.PROD]: [
    'social.echobox.com',
    'email.echobox.com',
    'newsletters.echobox.com',
    'benchmarks.echobox.com',
    'traffic.echobox.com',
    'social-cloudfront.service.echobox.com',
  ],
  [HOST_TYPES.STAGING]: ['echobox.dev'],
  [HOST_TYPES.DEV]: ['localhost', '127.0.0.1'],
};

export const AUTH_MESSAGE_TYPES = {
  GET_TOKENS: 'GET_TOKENS',
  SIGN_OUT: 'SIGN_OUT',
};
